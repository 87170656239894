<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{ this.$t('_common.How_can_I_use_this_form')}}</v-card-title>

          <v-card-text>{{ this.$t('_attend_list._info.content') }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{ this.$t('_common.OK') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->
    <v-flex v-if="!isStreaming">
      <v-text-field solo label="Enter livestreaming url: " v-model="streamUrl"></v-text-field>
      <div class="my-2">
        <v-btn small fab dark color="green" @click="startStreaming">
          <v-icon dark>mdi-play</v-icon>
        </v-btn>
      </div>
    </v-flex>
    <v-flex class="col-12">
      <video
        ref="player"
        id="stream-video"
        class="video-js full-height"
        controls
        style="width: 100%; height: 100%"
        preload="auto"
        data-setup="{}"
        autoplay
      >
        <source :src="streamUrl" type="application/x-mpegURL" />
      </video>
    </v-flex>
  </v-card>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
require("../../assets/css/neo.css");

export default {
  data() {
    return {
      isStreaming: false,
      streamUrl:
        "https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8",
      card_header_props: {
        header: {
          headLine: this.$t('_verticalNavbar.Livestream'),
          subTitle: this.$t('_attend_list.Attend_Leson_Text'),
          icon: "mdi-format-align-left"
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false
        }
      },
      player: null
    };
  },
  methods: {
    init() {},
    startStreaming() {
      this.player = videojs("stream-video");
      if (this.player) {
        let newStream = {
          type: "application/x-mpegURL",
          src: this.streamUrl
        };
        this.player.reset();
        this.player.src(newStream);
        this.player.load();
      }
      this.player.play();
      console.log(this.player);
    }
  },
  watch: {},
  mounted: function() {
    this.init();
  },
  beforeDestroy() {
    if (this.player) this.player.dispose();
  }
};
</script>
<style lang="scss">
.video-js .vjs-tech {
  position: inherit;
}

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

.scroll-area {
  position: relative;
  margin: auto;
  width: auto;
  height: 18rem;
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}
.video-js .vjs-big-play-button {
  left: calc(50% - 45px);
  top: calc(50% - 25px);
}
</style>
